import * as React from 'react';
import { Button } from '@invertase/ui';

import Modal from './Modal';
import Form from './Form';

interface Props {
  children: React.ReactNode | React.ReactElement;
  title: string;
  description: React.ReactNode;
  match: string;
  onConfirm: () => Promise<void>;
}

function PopConfirm({ title, description, match, children, onConfirm }: Props) {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [text, setText] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  function onClose() {
    if (!loading) {
      setText('');
      setVisible(false);
    }
  }

  function handleConfirm() {
    setLoading(true);
    onConfirm()
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(e => {
        setError(e);
        setLoading(false);
      });
  }

  return (
    <>
      <Modal visible={visible} title={title} onClose={onClose}>
        <article>{description}</article>
        <Form>
          <Form.Group>
            <Form.Label for="match">Confirm:</Form.Label>
            <Form.Input id="match" type="text" value={text} onChangeText={t => setText(t)} />
          </Form.Group>
          <Form.Group>
            <div>
              <Form.Error visible={!!error} message={error} />
            </div>
            <div className="flex justify-end">
              {!loading && (
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                  className="bg-gray-200 px-4 py-2 rounded text-base ml-2"
                >
                  Close
                </button>
              )}
              <Button
                disabled={loading || text !== match}
                onClick={() => {
                  if (text === match) {
                    handleConfirm();
                  }
                }}
                className="ml-2"
              >
                {loading ? 'Confirming...' : 'Confirm'}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal>
      <div role="button" onClick={() => setVisible(true)}>
        {children}
      </div>
    </>
  );
}

export default PopConfirm;

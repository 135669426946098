import React, { useState } from 'react';
import { Button, ButtonProps } from '@invertase/ui';

import Modal from '../../../components/Modal';
import Form from '../../../components/Form';
import { generateKey } from '../../../hooks/useApi';
import { LicenseKeyPlatform, LicenseKeyType } from '../../../types/account';

interface Props extends ButtonProps {
  lid: string;
  tid: string;
  type: LicenseKeyType;
  android: boolean;
  ios: boolean;
  request: () => Promise<void>;
}

function GenerateKey({ lid, tid, type, android, ios, request, children, ...props }: Props) {
  const generate = generateKey();
  const [visible, setVisible] = useState<boolean>(false);
  const [platform, setPlatform] = useState<LicenseKeyPlatform>(
    android ? LicenseKeyPlatform.IOS : LicenseKeyPlatform.ANDROID,
  );
  const [appId, setAppId] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function close() {
    setVisible(false);
  }

  function onDisplay() {
    setPlatform(android ? LicenseKeyPlatform.IOS : LicenseKeyPlatform.ANDROID);
    setAppId('');
    setError('');
    setLoading(false);
    setVisible(true);
  }

  async function onGenerate() {
    try {
      setLoading(true);
      await generate(tid, lid, {
        platform,
        app_id: appId,
        primary: type === 'primary',
      });
      await request();
      close();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        title={`Generate ${type === 'primary' ? 'Primary' : 'Secondary'} Key`}
        visible={visible}
        onClose={close}
      >
        <p className="text-base">
          {type === 'primary' && (
            <span>
              Primary keys are used to validate a license on a production application. Your license
              includes two platform specific keys for use on one Android & iOS application.
            </span>
          )}
          {type === 'secondary' && (
            <span>
              Secondary keys are useful for non-production applications, such as a testing or
              staging version of your application. These keys are subject to stricter checks, and
              may fail cause Notifee to stop working if they&apos;re used on a production app.
            </span>
          )}
        </p>
        <Form>
          <Form.Group>
            <Form.Label for="platform">Platform</Form.Label>
            <Form.Select
              id="platform"
              value={`${platform}`}
              onChangeOption={platform => setPlatform(parseInt(platform, 10))}
            >
              {type === 'secondary' && (
                <>
                  <Form.Option value="0" label="Android" />
                  <Form.Option value="1" label="iOS" />
                </>
              )}
              {type === 'primary' && (
                <>
                  {!android && <Form.Option value="0" label="Android" />}
                  {!ios && <Form.Option value="1" label="iOS" />}
                </>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            {platform === LicenseKeyPlatform.ANDROID && (
              <Form.Label for="app_id">Android Package Name</Form.Label>
            )}
            {platform === LicenseKeyPlatform.IOS && (
              <Form.Label for="app_id">iOS Bundle ID</Form.Label>
            )}
            <Form.Hint
              visible
              message={`This key will be assigned to a specific ${
                platform === LicenseKeyPlatform.ANDROID
                  ? 'Android package name'
                  : 'iOS bundle identifier'
              }. Once set, it cannot be changed and the key must be deleted and recreated.`}
            />
            <Form.Input id="app_id" value={appId} onChangeText={setAppId} />
            <Form.Error visible={!!error} message={error} />
          </Form.Group>
          <Form.Group>
            <div className="flex justify-end">
              <Button ghost onClick={close} className="mx-1" disabled={loading}>
                Close
              </Button>
              <Button onClick={onGenerate} className="mx-1" disabled={loading}>
                {loading ? 'Generating...' : 'Generate'}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal>
      <Button {...props} onClick={() => onDisplay()}>
        {children}
      </Button>
    </>
  );
}

export default GenerateKey;

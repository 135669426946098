import React from 'react';
import pick from 'lodash.pick';
import { icons } from '@invertase/ui';

import Card from '../../components/Card';
import Loader from '../../components/Loader';

import Licenses from './Overview/Licenses';
import Teams from './Overview/Teams';
import Invites from './Overview/Invites';
import Invoices from './Overview/Invoices';

import { UserContext } from '../../hooks/useUser';
import Link from '../../components/Link';

const { GitHub, Envelope, UserGroup } = icons;

function Account() {
  const user = React.useContext(UserContext);

  if (!user) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  const display = pick(user, [
    'display_name',
    'email',
    'photo_url',
    'provider_html_url',
    'provider_login',
  ]);

  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-3/4">
          <Card>
            <div className="flex items-center">
              <img
                src={display.photo_url}
                alt="Avatar"
                className="w-24 h-24 rounded-full shadow-lg mr-6"
              />
              <div className="flex-col justify-center">
                <div className="text-2xl">{display.display_name || display.email}</div>
                {display.provider_html_url && (
                  <div className="flex items-center mt-1 text-base text-gray-600">
                    <GitHub size={18} className="mr-2" />
                    <Link
                      to={display.provider_html_url}
                      target="_blank"
                      className="hover:underline"
                    >
                      {display.provider_login.toLowerCase()}
                    </Link>
                  </div>
                )}
                <div className="flex items-center mt-1 text-base text-gray-600">
                  <Envelope size={18} className="mr-2" />
                  {display.email}
                </div>
              </div>
            </div>
            <p className="text-base mt-6">
              This is your account page &nbsp;
              <span className="">{user.display_name}</span>. Here you will find your purchases,
              teams you are a member of or invites to teams you have not accepted yet.
            </p>
            <div className="flex items-center mt-8">
              <div className="inline-flex items-center text-base mr-4">
                <UserGroup size={14} className="mr-2" />
                {user.team_count || 0} Teams
              </div>
              <div className="inline-flex items-center text-base mr-4">
                <Envelope size={14} className="mr-2" />
                {user.pending_invite_count || 0} Pending Invites
              </div>
            </div>
          </Card>
        </div>
        <div className="lg:w-1/2 lg:mr-4" />
      </div>
      <div className="lg:flex mt-8">
        <div className="lg:w-1/2 lg:mr-4">
          <Licenses />
        </div>
        <div className="lg:w-1/2 lg:ml-4">
          <Teams />
        </div>
      </div>
      <div className="lg:flex mt-8">
        <div className="lg:w-1/2 lg:mr-4">
          <Invites />
        </div>
        <div className="lg:w-1/2 lg:ml-4">
          <Invoices />
        </div>
      </div>
    </>
  );
}

export default Account;

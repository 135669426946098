import * as React from 'react';
import { navigate } from 'gatsby';
import { icons } from '@invertase/ui';

import Card from '../../../components/Card';

import CreateTeam from '../../../components/CreateTeam';
import List from './List';
import Invitations from './Invitations';

const { UserGroup } = icons;

function Teams() {
  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-3/4">
          <Card>
            <div className="flex">
              <h1 className="flex flex-1 items-center text-3xl">
                <UserGroup size={28} className="mr-2" />
                Teams
              </h1>
              <div>
                <CreateTeam
                  callback={team => navigate(`/account/teams/${team.id}`)}
                  onClick={() => {
                    // Handled by component
                  }}
                >
                  Create Team &raquo;
                </CreateTeam>
              </div>
            </div>
            <p className="font-semibold text-base">
              Teams allow users to share and manage licenses which have been purchased by an
              individual.
            </p>
            <p className="text-base">
              When purchasing a new license, you have the option to select a team you own to be
              assigned to the license. Members of the team will have access to the license and can
              also manage the license with a sufficient role.
            </p>
          </Card>
        </div>
        <div className="lg:w-1/4" />
      </div>
      <br />
      <List />
      <br />
      <Invitations />
    </>
  );
}

export default Teams;

import React, { ReactNode, useContext } from 'react';
import cx from 'classnames';
import { Tooltip, icons } from '@invertase/ui';

import { UserContext } from '../../../hooks/useUser';

import { getUserLicenses } from '../../../hooks/useApi';
import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import { License, LicenseType } from '../../../types/account';
import Android from '../../../components/Icons/Android';
import Link from '../../../components/Link';
import IOS from '../../../components/Icons/IOS';

const { Key } = icons;

function renderRow(license: License) {
  return (
    <tr>
      <td className="pl-6" style={{ width: 120 }}>
        <span
          className={cx('px-2 py-1 rounded shadow text-sm', {
            'bg-blue-300': license.type === LicenseType.STANDARD,
            'bg-green-300': license.type === LicenseType.EXTENDED,
          })}
        >
          {license.type === LicenseType.STANDARD ? 'Standard' : 'Extended'}
        </span>
      </td>
      <td>{license.name || license.id}</td>
      <td>
        <Tooltip label="Total license keys">
          <div className="inline-flex items-center">
            <Key size={12} className="mr-2" /> {license.key_count} keys
          </div>
        </Tooltip>
      </td>
      <td>
        <div className="inline-flex items-center">
          <Android size={18} className="mr-2" />
          {license.primary_android_key_count === 0 && (
            <span className="text-sm">No primary key</span>
          )}
          {license.primary_android_key_count > 0 && (
            <span className="text-gray-500">
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </span>
          )}
        </div>
      </td>
      <td>
        <div className="inline-flex items-center">
          <IOS size={18} className="mr-2" />
          {license.primary_ios_key_count === 0 && <span className="text-sm">No primary key</span>}
          {license.primary_ios_key_count > 0 && (
            <span className="text-gray-500">
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </span>
          )}
        </div>
      </td>
      <td className="text-gray-500 text-sm pr-6">
        <Link className="hover:underline" to={`/account/licenses/${license.id}/${license.tid}`}>
          View &raquo;
        </Link>
      </td>
    </tr>
  );
}

function LicenseList() {
  const user = useContext(UserContext);
  const { loading, response, error } = getUserLicenses(user?.id || '');

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0, minHeight: 300 }}>
      <div className="px-6 pt-6 border-b-2">
        <h2 className="text-2xl mb-6">Licenses</h2>
      </div>
      {children}
    </Card>
  );

  if (!user || loading) {
    return wrapper(
      <div className="py-6">
        <Loader size="sm" />
      </div>,
    );
  }

  if (error || !response.licenses.length) {
    let e = 'You currently have no licenses';
    if (error) e = error;
    return wrapper(<div className="flex items-center justify-center py-12 text-gray-500">{e}</div>);
  }

  return wrapper(
    <table>
      <tbody>{response.licenses.map(renderRow)}</tbody>
    </table>,
  );
}

export default LicenseList;

import React, { ReactNode, useContext } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { Button, icons } from '@invertase/ui';

import { getUserLicenses } from '../../../hooks/useApi';
import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import Link from '../../../components/Link';
import { UserContext } from '../../../hooks/useUser';
import { LicenseType } from '../../../types/account';

const { Key } = icons;

function Licenses() {
  const user = useContext(UserContext);
  const { loading, response, error } = getUserLicenses(user?.id || '');

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <Key size={22} className="mr-4" />
          <h3 className="text-2xl">Licenses</h3>
        </div>
        <Button size="sm" ghost onClick={() => navigate('/account/licenses')}>
          View All &raquo;
        </Button>
      </div>
      <div className="overflow-y-auto h-48">{children}</div>
    </Card>
  );

  if (loading) {
    return wrapper(<Loader size="sm" />);
  }

  if (error || !response.licenses.length) {
    let e = 'You have no licenses';
    if (error) e = error;
    return wrapper(<div className="h-full flex items-center justify-center text-base">{e}</div>);
  }

  const { licenses } = response;

  return wrapper(
    <table>
      <tbody>
        {licenses.slice(0, 6).map(license => (
          <tr key={license.id}>
            <td className="pl-4" style={{ width: 200 }}>
              {license.name || license.id}
            </td>
            <td>
              <span
                className={cx('px-2 py-1 rounded shadow text-sm', {
                  'bg-blue-300': license.type === LicenseType.STANDARD,
                  'bg-green-300': license.type === LicenseType.EXTENDED,
                })}
              >
                {license.type === LicenseType.STANDARD ? 'Standard' : 'Extended'}
              </span>
            </td>
            <td>
              <div className="inline-flex items-center">
                <Key size={14} className="mr-2 text-gray-500" />
                <span> {license.key_count} keys</span>
              </div>
            </td>
            <td>
              <Link
                className="font-sm text-gray-500 hover:underline"
                to={`/account/licenses/${license.id}/${license.tid}`}
              >
                View &raquo;
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>,
  );
}

export default Licenses;

import axios, { AxiosRequestConfig } from 'axios';
import getFirebase from '../firebase';
import { useEffect, useState } from 'react';

type ApiMethod = 'get' | 'post' | 'patch' | 'delete';

const instance = axios.create({
  baseURL: 'https://api.notifee.app',
  // baseURL: 'http://localhost:8080',
  headers: {
    'X-Source': 'notifee',
  },
});

async function getIdToken(): Promise<string> {
  const firebase = getFirebase();

  if (!firebase) {
    return '';
  }

  const user = firebase.auth().currentUser;

  if (!user) {
    return '';
  }

  return user.getIdToken(true);
}

async function request(method: ApiMethod, path: string, data?: object) {
  const config: AxiosRequestConfig = {
    method,
    url: path,
    headers: {
      authorization: `Bearer ${await getIdToken()}`,
    },
  };

  if (method === 'get') config.params = data;
  else config.data = data;

  return instance(config)
    .then($ => $.data.payload)
    .catch(e => Promise.reject(e?.response?.data?.message || 'Something went wrong'));
}

const api = {
  get: async <T>(path: string, params?: object): Promise<T> => {
    return request('get', path, params);
  },
  post: async <T>(path: string, body?: object): Promise<T> => {
    return request('post', path, body);
  },
  patch: async <T>(path: string, body?: object): Promise<T> => {
    return request('patch', path, body);
  },
  delete: async <T>(path: string, body?: object): Promise<T> => {
    return request('delete', path, body);
  },
};

export default api;

interface UseRequestHook<T> {
  loading: boolean;
  response: T;
  error?: string;
  request: () => Promise<void>;
}

export function useRequest<T>(
  initialData: any,
  method: ApiMethod,
  path: string,
  reRequest: boolean,
  data?: object,
): UseRequestHook<T> {
  const [loading, setLoading] = useState<boolean>(method === 'get');
  const [error, setError] = useState<string>();
  const [response, setResponse] = useState<T>(initialData);

  function call() {
    return request(method, path, data)
      .then(response => setResponse(response as T))
      .catch(e => setError(e?.response?.data?.message || 'Something went wrong'))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (method === 'get') {
      call();
    }
  }, [method, reRequest]);

  return { loading, response, error, request: call };
}

export function useGet<T>(initialData: any, path: string, reRequest = false, params?: object) {
  return useRequest<T>(initialData, 'get', path, reRequest, params);
}

export function getTeams() {
  return api.get('/teams');
}

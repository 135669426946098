import debounce from 'lodash.debounce';

import {
  Invite,
  Invoice,
  LicenseKeyPlatform,
  LicenseResponse,
  LicensesResponse,
  PaddleCheckoutResponse,
  Roles,
  Team,
  TeamResponse,
} from '../types/account';
import api, { useGet } from '../utils/api';

export function stripeCheckout() {
  return debounce((data: object) => api.post<Team>('/stripe/checkout', data), 700, {
    leading: true,
  });
}

export function paddleCheckout() {
  return debounce(
    (data: object) => api.post<PaddleCheckoutResponse>('/paddle/checkout', data),
    700,
    {
      leading: true,
    },
  );
}

/**
 * Licenses
 */

export function getUserLicenses(uid: string) {
  return useGet<LicensesResponse>([], `/teams/${uid}/licenses`);
}

export function getLicense(tid: string, lid: string) {
  return useGet<LicenseResponse>([], `/teams/${tid}/licenses/${lid}`);
}

export function updateLicense() {
  return debounce(
    (tid: string, lid: string, name: string) =>
      api.patch<void>(`/teams/${tid}/licenses/${lid}`, { name }),
    700,
    { leading: true },
  );
}

export function assignTeam() {
  return debounce(
    (tid: string, lid: string, newTid) =>
      api.patch<void>(`/teams/${tid}/licenses/${lid}`, { tid: newTid }),
    700,
    { leading: true },
  );
}

/**
 * License Keys
 */

export function generateKey() {
  return debounce(
    (
      tid: string,
      lid: string,
      data: {
        primary: boolean;
        app_id: string;
        platform: LicenseKeyPlatform;
      },
    ) => api.post<void>(`/teams/${tid}/licenses/${lid}/keys`, data),
    700,
    { leading: true },
  );
}

export function removeKey() {
  return debounce(
    (tid: string, lid: string, kid: string) =>
      api.delete<void>(`/teams/${tid}/licenses/${lid}/keys/${kid}`),
    700,
    { leading: true },
  );
}

export function regenerateKey() {
  return debounce(
    (tid: string, lid: string, kid: string) =>
      api.patch<void>(`/teams/${tid}/licenses/${lid}/keys/${kid}`),
    700,
    { leading: true },
  );
}

/**
 * Misc
 */

export function getInvoices() {
  return useGet<Invoice[]>([], '/user/invoices');
}

export function getInvoice() {
  return debounce((iid: string) => api.get<{ url: string }>(`/user/invoices/${iid}`), 700, {
    leading: true,
  });
}

export function getInvites() {
  return useGet<Invite[]>([], '/user/invites');
}

/**
 * Teams
 */

export function getTeams(reRequest = false) {
  return useGet<TeamResponse[]>([], '/teams', reRequest);
}

export function getTeam(id: string) {
  return useGet<TeamResponse>(null, `/teams/${id}`);
}

export function createTeam() {
  return debounce(
    (name: string) => api.post<Team>('/teams', { name }),
    700,
    { leading: true },
  );
}

export function removeTeam() {
  return debounce(
    (tid: string) => api.delete<void>(`/teams/${tid}`, { name }),
    700,
    { leading: true },
  );
}

export function inviteTeamMember() {
  return debounce(
    (tid: string, username: string, role: Roles) =>
      api.post<Team>(`/teams/${tid}/invites`, { role, invitee_provider_login: username }),
    700,
    { leading: true },
  );
}

export function getTeamInvitations(tid: string) {
  return useGet<Invite[]>([], `/teams/${tid}/invites`);
}

export function cancelTeamInvitation() {
  return debounce(
    (tid: string, iid: string) => api.delete<void>(`/teams/${tid}/invites/${iid}`),
    700,
    { leading: true },
  );
}

export function acceptInvite() {
  return debounce(
    (iid: string) => api.patch<void>(`/user/invites/${iid}`, { accepted: true }),
    700,
    { leading: true },
  );
}

export function declineInvite() {
  return debounce(
    (iid: string) => api.patch<void>(`/user/invites/${iid}`, { accepted: false }),
    700,
    { leading: true },
  );
}

export function removeTeamMember() {
  return debounce(
    (tid: string, mid: string) => api.delete<void>(`/teams/${tid}/members/${mid}`),
    700,
    { leading: true },
  );
}

export function updateTeamMember() {
  return debounce(
    (tid: string, mid: string, role: Roles) =>
      api.patch<void>(`/teams/${tid}/members/${mid}`, { role }),
    700,
    { leading: true },
  );
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, ButtonProps } from '@invertase/ui';

import Modal from './Modal';
import Form from './Form';
import { createTeam } from '../hooks/useApi';
import { Team } from '../types/account';

const REGEX = /^[a-zA-Z0-9 _-]*$/;

interface CreateTeamProps extends ButtonProps {
  callback: (team: Team, setVisible: Dispatch<SetStateAction<boolean>>) => void;
  form?: boolean;
}

function CreateTeam({ size, children, form = true, callback, ...props }: CreateTeamProps) {
  const create = createTeam();

  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');

  function close() {
    if (!loading) {
      setVisible(false);
    }
  }

  function onError(message: string) {
    setError(message);
    setLoading(false);
  }

  function onCreate() {
    if (name.length > 2 && name.length <= 32 && REGEX.test(name)) {
      setLoading(true);
      create(name)
        .then(team => {
          callback(team, setVisible);
        })
        .catch(onError);
    }
  }

  function body() {
    return (
      <>
        <Form.Group>
          <Form.Label for="name">Team Name</Form.Label>
          <Form.Input id="name" value={name} onChangeText={setName} />
          <Form.Error visible={!!error} message={error} />
          <Form.Error visible={name.length < 3} message="Team name too short." />
          <Form.Error visible={name.length > 32} message="Team name too long." />
          <Form.Error
            visible={!!name && !REGEX.test(name)}
            message="Team name invalid, must only include alphanumeric characters, spaces, underscores and dashes."
          />
        </Form.Group>
        <Form.Group>
          <div className="flex justify-end">
            <Button ghost onClick={close} className="mx-1">
              Close
            </Button>
            <Button disabled={loading} onClick={onCreate} className="mx-1">
              {loading ? 'Creating...' : 'Create'}
            </Button>
          </div>
        </Form.Group>
      </>
    );
  }

  return (
    <>
      <Modal title="Create Team" visible={visible} onClose={close}>
        <p className="text-base">
          Creating a team allows you to share licenses with members within a team. Once created, you
          can invite more members to join your team.
        </p>
        {form ? <Form>{body()}</Form> : body()}
      </Modal>
      <Button
        ghost={props.ghost}
        className={props.className}
        onClick={() => setVisible(true)}
        size={size}
      >
        {children}
      </Button>
    </>
  );
}

export default CreateTeam;

import * as React from 'react';
import { navigate } from 'gatsby';
import { Button, icons } from '@invertase/ui';
import cx from 'classnames';

import Card from '../../../components/Card';
import { getTeam, removeTeam } from '../../../hooks/useApi';
import Link from '../../../components/Link';
import Members from './Members';
import MemberInvitations from './MemberInvitations';
import PopConfirm from '../../../components/PopConfirm';
import Loader from '../../../components/Loader';
import Licenses from './Licenses';
import { Roles } from '../../../types/account';

const { UserGroup, Key } = icons;

interface Props {
  id?: string;
}

function Team({ id }: Props) {
  const { loading, response, error, request } = getTeam(id || '');
  const remove = removeTeam();

  if (loading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (!id || error) {
    return (
      <Card>
        <div className="py-12 flex flex-col items-center justify-center">
          <h1 className="text-2xl mb-6">Team Not Found</h1>
          <div>
            Team <code>{id}</code> could not be found.
          </div>
        </div>
      </Card>
    );
  }

  const { team, members, role, licenses } = response;

  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <Card>
            <Link to="/account/teams" className="text-base text-gray-500">
              &laquo; Back to teams
            </Link>
            <div className="flex items-center mt-2 mb-6">
              <UserGroup size={28} className="mr-2" />
              <h1 className="flex-1 text-3xl">{team.name}</h1>
            </div>
            <p className="text-base">
              This is the team page for <span>{team.name}</span>. The team contains members with
              roles, and any licenses which have been assigned to the team.
            </p>
            {role === Roles.ADMIN && (
              <p className="text-base">
                To invite a new team member, click on <code>Invite User</code> below and enter the
                users GitHub username.
              </p>
            )}
            <div className="flex items-center">
              <div className="flex-1">
                <div className="inline-flex items-center text-base mr-4">
                  <UserGroup size={14} className="mr-2" />
                  {members.length} Members
                </div>
                <div className="inline-flex items-center text-base mr-4">
                  <Key size={14} className="mr-2" />
                  {licenses.length} Licenses
                </div>
                <span className="text-gray-300">|</span>
                <div
                  className={cx('inline-flex px-2 py-1 rounded shadow text-sm ml-4', {
                    'bg-gray-300': role === Roles.USER,
                    'bg-teal-300': role === Roles.ADMIN,
                  })}
                >
                  {role === Roles.ADMIN ? 'admin' : 'user'}
                </div>
              </div>
              {role === Roles.ADMIN &&
                // team.pending_invite_count === 0 &&
                members.length === 1 &&
                licenses.length === 0 && (
                  <PopConfirm
                    title="Delete Team"
                    description={
                      <p className="text-base">
                        Type the team name <span>{team.name}</span> below to confirm that you wish
                        to delete the team.
                      </p>
                    }
                    match={team.name}
                    onConfirm={async () => {
                      await remove(team.id);
                      navigate('/account/teams');
                    }}
                  >
                    <Button
                      ghost
                      size="sm"
                      onClick={() => {
                        // propagated...
                      }}
                    >
                      Delete Team
                    </Button>
                  </PopConfirm>
                )}
            </div>
          </Card>
        </div>
        <div className="lg:w-1/3" />
      </div>
      <br />
      <div className="lg:flex">
        <div className="lg:w-1/2 mr-4">
          <Members team={id} role={role} members={members} request={request} />
        </div>
        <div className="lg:w-1/2 ml-4">
          <Licenses licenses={licenses} />
        </div>
      </div>
      <br />
      {role === Roles.ADMIN && <MemberInvitations team={id} />}
    </>
  );
}

export default Team;

import React, { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { Button } from '@invertase/ui';

import Card from '../../../components/Card';
import { acceptInvite, declineInvite, getInvites } from '../../../hooks/useApi';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';

function Invitations() {
  const accept = acceptInvite();
  const decline = declineInvite();
  const [pending, setPending] = useState<{ [key: string]: boolean }>({});

  const { loading, response: invitations, error, request } = getInvites();

  function onAccept(iid: string, tid: string) {
    setPending($ => ({ ...$, [iid]: true }));
    accept(iid).then(() => {
      navigate(`/account/teams/${tid}`);
    });
  }

  function onDecline(iid: string) {
    setPending($ => ({ ...$, [iid]: true }));
    decline(iid).then(request);
  }

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0, minHeight: 300 }}>
      <div className="px-6 py-6 border-b-2">
        <h3 className="text-2xl">Invitations</h3>
      </div>
      {children}
    </Card>
  );

  if (loading) {
    return wrapper(
      <div className="py-12">
        <Loader size="sm" />
      </div>,
    );
  }

  if (error || !invitations.length) {
    let e = 'You currently have no pending invites.';
    if (error) e = error;
    return wrapper(<div className="py-24 flex items-center justify-center text-gray-500">{e}</div>);
  }

  return wrapper(
    <div style={{ minHeight: 150 }}>
      <table>
        <tbody>
          {invitations.map(invite => (
            <tr key={invite.id}>
              <td style={{ width: 70 }}>
                <img
                  src={invite.inviter_photo_url}
                  alt=""
                  className="w-8 h-8 rounded-full shadow"
                />
              </td>
              <td>
                <span>You have been invited to </span>
                <b>{invite.team_name}</b>
                <span> by </span>
                <Link
                  to={invite.inviter_provider_html_url}
                  target="_blank"
                  className="font-semibold hover:underline"
                >
                  {invite.inviter_provider_login}
                </Link>
                <span> on the </span>
                {dayjs(invite.created_at).format('DD/MM/YYYY')}.
              </td>
              <td>
                <div className="flex justify-end">
                  <Button
                    size="sm"
                    ghost
                    onClick={() => onDecline(invite.id)}
                    className="mx-1"
                    disabled={pending[invite.id]}
                  >
                    Decline
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => onAccept(invite.id, invite.tid)}
                    className="mx-1"
                    disabled={pending[invite.id]}
                  >
                    Accept
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>,
  );
}

export default Invitations;

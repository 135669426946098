export interface Config {
  payment: boolean;
  account: boolean;
}

export interface DatabaseRecord {
  id: string;
  created_at: string;
  updated_at: string;
}

export interface User extends DatabaseRecord {
  email: string;
  display_name: string;
  photo_url: string;
  pending_invite_count: number;
  team_count: number;
  provider_company: string;
  provider_html_url: string;
  provider_login: string;
  provider_node_id: string;
  provider_uid: string;
}

export enum Roles {
  USER = 5,
  ADMIN = 10,
}

export enum LicenseType {
  STANDARD = 5,
  EXTENDED = 10,
}

export interface LicensesResponse {
  team: Team;
  role: Roles;
  licenses: License[];
}

export interface LicenseResponse {
  team: Team;
  role: Roles;
  license: License;
  keys: LicenseKey[];
}

export interface License extends DatabaseRecord {
  uid: string;
  tid: string;
  name?: string;
  type: LicenseType;
  key_count: number;
  primary_android_key_count: number;
  primary_ios_key_count: number;
  metadata: {
    p_country: string;
    p_coupon: string;
    p_coupon_savings: string;
    p_currency: string;
    p_order_id: string;
    p_price: string;
    p_product_id: string;
    p_quantity: string;
    p_sale_gross: string;
    p_tax_amount: string;
  };
}

export interface Invoice extends License {
  lid: string;
}

export enum LicenseKeyPlatform {
  ANDROID,
  IOS,
}

export type LicenseKeyType = 'secondary' | 'primary';

export interface LicenseKey extends DatabaseRecord {
  /**
   * The user's `uid` that created this key.
   */
  uid: string;

  /**
   * The license id that this key is for.
   */
  lid: string;

  /**
   * The team id this license key is assigned to, defaults to `uid`.
   */
  tid: string;

  /**
   * Platform this key is valid for.
   */
  platform: LicenseKeyPlatform;

  /**
   * App/Bundle identifier.
   */
  app_id: string;

  /**
   * The actual license key, a JWT that's generated on `app_id` being modified.
   */
  key: string;

  /**
   * Whether this is a primary, or 'production' key. False indicates a secondary key
   * such as for staging environments.
   */
  primary: boolean;
}

export interface Team extends DatabaseRecord {
  /**
   * The user customisable team name.
   */
  name: string;

  /**
   * An atomically incremented count of the number of admins in this team.
   * Use to prevent all admins being removed from a team and therefore no-one
   * being able to administrate the team anymore. Should always be ≥ 1.
   */
  admin_count: number;

  /**
   * An atomically incremented count of the number of purchased licenses assigned to this team.
   */
  license_count: number;

  /**
   * An atomically incremented count of the number of users assigned to this team.
   */
  member_count: number;

  /**
   * An atomically incremented count of the number of invites that are pending
   * acceptance for this team.
   */
  pending_invite_count: number;
}

export interface TeamMemberWithUser {
  member: TeamMember;
  user: User;
}

export interface TeamResponse {
  team: Team;
  role: Roles;
  members: TeamMemberWithUser[];
  licenses: License[];
}

export interface TeamLicenseResponse {
  license: License;
  team: Team;
  keys: LicenseKey[];
}

export interface Invite extends DatabaseRecord {
  /**
   * The team id this invite relates to.
   */
  tid: string;

  /**
   * The name (at the time of this invite) of the team to be invited to;
   * for display purposes only.
   */
  team_name: string;

  /**
   * The user's `uid` that created this invite.
   */
  inviter_uid: string;

  /**
   * The display name (at the time of this invite) of the user who created this invite;
   * for display purposes only.
   */
  inviter_display_name: string;

  /**
   * The role the user will be given once the invite is accepted.
   */
  role: Roles;

  /**
   * The GitHub UID of the person being invited.
   */
  invitee_provider_uid: string;

  /**
   * The GitHub Username (at the time of this invite) of the person being invited;
   * for display purposes only.
   */
  invitee_provider_login: string;

  /**
   * The GitHub avatar of the person being invited.
   */
  invitee_photo_url: string;

  /**
   * The GitHub name of the person being invited, can be null, user provider_login if name is null.
   */
  invitee_display_name: string;

  /**
   * Whether this invite has been accepted yet.
   * Defaults to false, query by false for display purposes.
   */
  invitee_accepted: boolean | null;

  /**
   * The Notifee User ID of the person who can accept this invite, null if user does not exist yet on notifee
   */
  invitee_uid: string | null;

  /**
   * Firestore Timestamp of when the invite was accepted.
   */
  invitee_accepted_at: string | null;

  /**
   * Inviter photo url.
   */
  inviter_photo_url: string;

  /**
   * Inviter GitHub URL.
   */
  inviter_provider_html_url: string;

  /**
   * Inviter login name
   */
  inviter_provider_login: string;

  /**
   * Invitee GitHub URL.
   */
  invitee_provider_html_url: string;
}

export interface TeamMember extends DatabaseRecord {
  /**
   * The team id this member is assigned to, for querying purposes.
   */
  tid: string;

  /**
   * The members role in this team.
   */
  role: Roles;
}

export interface PaddleCheckoutResponse {
  url: string;
}

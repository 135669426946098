import React, { ReactNode } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby-link';
import { Button, icons } from '@invertase/ui';

import { getTeams } from '../../../hooks/useApi';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import Link from '../../../components/Link';
import CreateTeam from '../../../components/CreateTeam';
import { Roles } from '../../../types/account';

const { UserGroup } = icons;

function Teams() {
  const { loading, response: teams, error } = getTeams();

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <UserGroup size={22} className="mr-4" />
          <h3 className="text-2xl">Teams</h3>
        </div>
        <CreateTeam
          callback={team => navigate(`/account/teams/${team.id}`)}
          ghost
          size="sm"
          className="ml-1"
          onClick={() => {
            // Handled by component
          }}
        >
          Create +
        </CreateTeam>
        <Button ghost size="sm" onClick={() => navigate('/account/teams')} className="ml-1">
          View All &raquo;
        </Button>
      </div>
      <div className="overflow-y-auto h-48">{children}</div>
    </Card>
  );

  if (loading) {
    return wrapper(<Loader size="sm" />);
  }

  if (error || !teams.length) {
    let e = 'You have no teams';
    if (error) e = error;
    return wrapper(<div className="h-full flex items-center justify-center text-base">{e}</div>);
  }

  return wrapper(
    <table>
      <tbody>
        {!loading &&
          teams.slice(0, 6).map(({ role, team }) => (
            <tr key={team.id}>
              <td className="pl-4" style={{ width: 200 }}>
                {team.name || team.id}
              </td>
              <td>
                <span
                  className={cx('px-2 py-1 rounded shadow text-sm', {
                    'bg-gray-300': role === Roles.USER,
                    'bg-teal-300': role === Roles.ADMIN,
                  })}
                >
                  {role === Roles.ADMIN ? 'Admin' : 'User'}
                </span>
              </td>
              <td>{team.member_count} members</td>
              <td>
                <Link
                  className="font-sm text-gray-500 hover:underline"
                  to={`/account/teams/${team.id}`}
                >
                  View &raquo;
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>,
  );
}

export default Teams;

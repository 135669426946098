import React, { ReactElement } from 'react';
import { icons } from '@invertase/ui';
import { Match, Redirect, RouteComponentProps, Router } from '@reach/router';
import cx from 'classnames';

import Page from '../components/Page';
import Header from '../components/Header';
import Container from '../components/Container';
import Link from '../components/Link';

import Account from '../templates/account/Account';
import Licenses from '../templates/account/Licenses';
import License from '../templates/account/Licenses/License';
import Teams from '../templates/account/Teams';
import Team from '../templates/account/Teams/Team';

import { getUser, UserContext } from '../hooks/useUser';

import { signOutUser } from '../firebase/user';
import Footer from '../components/Footer';
import useConfig from '../hooks/useConfig';

const { Key, Cog, UserGroup, Envelope } = icons;

// https://github.com/reach/router/issues/141#issuecomment-451646939
const RouterPage = (props: { render: JSX.Element } & RouteComponentProps) =>
  React.cloneElement(props.render, props);

function AccountPage({ location }: { location: Location }) {
  const [configLoading, config] = useConfig();
  const [userLoading, user] = getUser();

  if (userLoading || configLoading) {
    return null;
  }

  if (!user) {
    return <Redirect noThrow to="/login" state={{ sendTo: location.pathname }} />;
  }

  if (!config.account) {
    return (
      <Page
        location={location}
        title="Account"
        className="bg-gray-100 dark:bg-gray-700"
        header={<Header search={false} />}
      >
        <div className="text-center flex flex-col items-center justify-center h-64 mt-16">
          <h1 className="text-3xl">Account Maintenance</h1>
          <p>The account area is currently undergoing maintenance, please check back soon.</p>
        </div>
        <Footer />
      </Page>
    );
  }

  return (
    <UserContext.Provider value={user}>
      <Page
        location={location}
        title="Account"
        className="bg-gray-100 dark:bg-gray-700"
        header={<Header search={false} />}
      >
        <div className="bg-gray-800 h-48 w-full">
          <Container size="sm">
            <div className="flex items-center h-16 text-white">
              <div className="flex-1 flex">
                <MenuItem exact to="/" icon={<Cog />}>
                  Account
                </MenuItem>
                <MenuItem to="/licenses" icon={<Key />}>
                  Licenses
                </MenuItem>
                <MenuItem to="/teams" icon={<UserGroup />}>
                  <>
                    <span>Teams</span>
                    {user.pending_invite_count > 0 && (
                      <div
                        className="text-base pl-1 flex items-center relative ml-2"
                        style={{ top: 2 }}
                      >
                        <Envelope size={12} className="mr-1" />
                        <span className="text-xs">{user.pending_invite_count}</span>
                      </div>
                    )}
                  </>
                </MenuItem>
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => signOutUser()}
                onKeyPress={() => signOutUser()}
                className="font-thin text-purple-800 bg-white px-3 py-1 rounded shadow hover:bg-gray-100"
              >
                Log Out
              </div>
            </div>
            <p className="text-red-600 text-center bg-gray-300 font-bold rounded p-2 mt-0">
              Notifee is now{' '}
              <a href="https://invertase.io/blog/open-sourcing-notifee">
                free and fully open-source
              </a>
              . These account pages will be removed in future.
            </p>
          </Container>
        </div>
        <Container size="sm">
          <div className="-mt-16" style={{ minHeight: '65vh' }}>
            <Router basepath="/account">
              <RouterPage path="/" render={<Account />} />
              <RouterPage path="/licenses" render={<Licenses />} />
              <RouterPage path="/licenses/:lid/:tid" render={<License />} />
              <RouterPage path="/teams" render={<Teams />} />
              <RouterPage path="/teams/:id" render={<Team />} />
            </Router>
          </div>
        </Container>
        <Footer />
      </Page>
    </UserContext.Provider>
  );
}

interface MenuItemProps {
  to: string;
  icon: ReactElement;
  children: ReactElement | string;
  exact?: boolean;
}

function MenuItem({ icon, to, children, exact }: MenuItemProps) {
  return (
    <Match path={exact ? `/account${to}` : `/account${to}/*`}>
      {props => (
        <Link
          className={cx('inline-block px-3 py-1 mr-2 rounded font-thin hover:bg-gray-900', {
            'bg-purple-200 hover:bg-purple-200 text-purple-500': props.match,
          })}
          to={`/account${to}`}
        >
          <span className="inline-flex items-center">
            {React.cloneElement(icon, {
              size: 16,
              className: cx('mr-2', {
                'text-white': !props.match,
              }),
            })}
            {children}
          </span>
        </Link>
      )}
    </Match>
  );
}

export default AccountPage;

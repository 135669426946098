import React, { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Loader, icons } from '@invertase/ui';

import Card from '../../../components/Card';
import { cancelTeamInvitation, getTeamInvitations } from '../../../hooks/useApi';
import InviteMember from './InviteMember';
import Link from '../../../components/Link';
import cx from 'classnames';
import { Roles } from '../../../types/account';
import { roleToString } from '../../../utils/firestore';

const { Envelope } = icons;

interface Props {
  team: string;
}

function MemberInvitations({ team }: Props) {
  const cancel = cancelTeamInvitation();
  const [pending, setPending] = useState<{ [key: string]: boolean }>({});

  const { loading, response: invitations, error, request } = getTeamInvitations(team);

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <Envelope size={16} className="mr-2" />
          <h3 className="text-2xl">Pending Invitations</h3>
        </div>
        <InviteMember
          team={team}
          size="sm"
          onInvite={request}
          onClick={() => {
            // Handled by component
          }}
        >
          Invite Member &raquo;
        </InviteMember>
      </div>
      <div style={{ minHeight: 200 }}>{children}</div>
    </Card>
  );

  if (loading) {
    return wrapper(
      <div className="py-12">
        <Loader size="sm" />
      </div>,
    );
  }

  if (error || !invitations.length) {
    let e = 'This team currently has no pending invites.';
    if (error) e = error;
    return wrapper(<div className="py-24 flex items-center justify-center text-gray-500">{e}</div>);
  }

  function onCancel(id: string) {
    setPending($ => ({ ...$, [id]: true }));
    cancel(team, id).then(request);
  }

  return wrapper(
    <table>
      <tbody>
        {invitations.map(invite => (
          <tr key={invite.id}>
            <td style={{ width: 70 }} className="pl-4">
              <div className="flex">
                <img
                  src={invite.inviter_photo_url}
                  alt=""
                  className="w-8 h-8 rounded-full border shadow"
                />
                <img
                  src={invite.invitee_photo_url}
                  alt=""
                  className="relative w-8 h-8 rounded-full border shadow"
                  style={{ left: -20 }}
                />
              </div>
            </td>
            <td>
              <span
                className={cx('px-2 py-1 rounded shadow text-sm', {
                  'bg-gray-300': invite.role === Roles.USER,
                  'bg-teal-300': invite.role === Roles.ADMIN,
                })}
              >
                {roleToString(invite.role)}
              </span>
            </td>
            <td>
              <Link
                className="font-semibold hover:underline"
                to={invite.invitee_provider_html_url}
                target="_blank"
              >
                {invite.invitee_provider_login}
              </Link>
              <span> was invited with role `{invite.role == 5 ? 'USER' : 'ADMIN'}` by </span>
              <Link
                className="font-semibold hover:underline"
                to={invite.inviter_provider_html_url}
                target="_blank"
              >
                {invite.inviter_provider_login}
              </Link>
              <span> on the </span>
              <span className="font-semibold">{dayjs(invite.created_at).format('DD/MM/YYYY')}</span>
            </td>

            <td className="pr-4">
              <Button
                disabled={pending[invite.id]}
                ghost
                size="sm"
                onClick={() => onCancel(invite.id)}
                className="ml-auto"
              >
                Cancel
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>,
  );
}

export default MemberInvitations;

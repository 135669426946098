import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip, ActionIcon, icons } from '@invertase/ui';

const { EditCopy } = icons;

interface Props {
  text: string;
}

function Copy({ text }: Props) {
  return (
    <Tooltip label="Copy">
      <CopyToClipboard text={text}>
        <ActionIcon
          icon={<EditCopy />}
          onClick={() => {
            // Propagated
          }}
        />
      </CopyToClipboard>
    </Tooltip>
  );
}

export default Copy;

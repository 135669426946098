import React from 'react';
import { Loader as LoaderUI, LoaderProps } from '@invertase/ui';

function Loader(props: LoaderProps) {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <LoaderUI {...props} />
    </div>
  );
}

export default Loader;

import React, { useReducer } from 'react';
import cx from 'classnames';
import { Button, icons } from '@invertase/ui';

import GenerateKey from './GenerateKey';

import Card from '../../../components/Card';
import Android from '../../../components/Icons/Android';
import Ios from '../../../components/Icons/IOS';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import AssignTeam from './AssignTeam';
import CopyToClipboard from '../../../components/CopyToClipboard';

import { getLicense, regenerateKey, removeKey, updateLicense } from '../../../hooks/useApi';
import { LicenseKey, LicenseKeyPlatform, LicenseType, Roles } from '../../../types/account';
import PopConfirm from '../../../components/PopConfirm';
import Form from '../../../components/Form';

const { Key, UserGroup, LockClosed } = icons;

interface KeyTableProps {
  role: Roles;
  team: string;
  license: string;
  keys: LicenseKey[];
  request: () => Promise<void>;
}

function KeyTable({ role, keys, team, license, request }: KeyTableProps) {
  const remove = removeKey();
  const regenerate = regenerateKey();

  if (keys.length === 0) {
    return (
      <div className="flex items-center justify-center py-12 text-gray-500">
        No keys have been generated.
      </div>
    );
  }

  return (
    <table>
      <tbody>
        {keys.map(({ id, platform, app_id, key }) => {
          return (
            <tr key={id}>
              <td className="text-base pl-6" style={{ width: 70 }}>
                {platform === LicenseKeyPlatform.ANDROID ? <Android /> : <Ios />}
              </td>
              <td style={{ maxWidth: 100 }}>
                <div className="font-semibold">{app_id}</div>
                <div className="flex items-center">
                  <LockClosed size={14} className="mr-2 relative" style={{ top: 3 }} />
                  <div className="flex-1 text-sm mt-2 mr-1 whitespace-no-wrap overflow-x-scroll truncate">
                    {key}
                  </div>
                  <CopyToClipboard text={key} />
                </div>
              </td>
              <td className="pr-6">
                {role === Roles.ADMIN && (
                  <div className="flex justify-end">
                    <PopConfirm
                      title="Regenerate Key"
                      description={
                        <>
                          <p>
                            Regenerating a key will cause any applications using the current key to
                            stop displaying Notifications.{' '}
                            <Link to="/" className="hover:underline text-gray-500">
                              Read more &raquo;
                            </Link>
                          </p>
                          <p>
                            To confirm you want to refresh the key for application{' '}
                            <span className="font-semibold">{app_id}</span>, enter the application
                            ID below:
                          </p>
                        </>
                      }
                      match={app_id}
                      onConfirm={async () => {
                        await regenerate(team, license, id);
                        await request();
                      }}
                    >
                      <Button
                        size="sm"
                        ghost
                        className="ml-2"
                        onClick={() => {
                          // propagated...
                        }}
                      >
                        Regenerate
                      </Button>
                    </PopConfirm>
                    <PopConfirm
                      title="Delete Key"
                      description={
                        <>
                          <p>
                            Deleting a key will cause any applications using this key to stop
                            displaying Notifications.{' '}
                            <Link to="/" className="hover:underline text-gray-500">
                              Read more &raquo;
                            </Link>
                          </p>
                          <p>
                            To confirm you want to delete the key for application{' '}
                            <span className="font-semibold">{app_id}</span>, enter the application
                            ID below:
                          </p>
                        </>
                      }
                      match={app_id}
                      onConfirm={async () => {
                        await remove(team, license, id);
                        await request();
                      }}
                    >
                      <Button
                        size="sm"
                        ghost
                        className="ml-2"
                        onClick={() => {
                          // propagated
                        }}
                      >
                        Delete
                      </Button>
                    </PopConfirm>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface Props {
  lid?: string;
  tid?: string;
}

interface EditState {
  editing: boolean;
  saving: boolean;
  name: string;
  error: string;
}

function editReducer(
  state: EditState,
  action: { type: string; value?: boolean | string },
): EditState {
  switch (action.type) {
    case 'edit':
      return { ...state, editing: true, name: '' };
    case 'cancel':
      return { ...state, editing: false, name: '' };
    case 'update':
      return { ...state, name: action.value as string };
    case 'pending':
      return { ...state, saving: action.value as boolean };
    case 'error':
      return { ...state, error: action.value as string };
    default:
      return state;
  }
}

function License({ lid, tid }: Props) {
  const { loading, response, error, request } = getLicense(tid || '', lid || '');
  const update = updateLicense();

  const [state, dispatch] = useReducer(editReducer, {
    saving: false,
    editing: false,
    name: '',
    error: '',
  });

  if (loading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (!lid || !tid || !response || error) {
    let e = 'No license found';
    if (error) e = error;
    return <Card>{e}</Card>;
  }

  // Update the name
  async function updateName() {
    if (!tid || !lid) return;
    dispatch({ type: 'pending', value: true });
    try {
      await update(tid, lid, state.name);
      await request();
      dispatch({ type: 'pending', value: false });
      dispatch({ type: 'cancel' });
    } catch (e) {
      dispatch({ type: 'error', value: e });
    }
  }

  const { license, keys, team, role } = response;

  const hasTeam = license.uid !== team.id;
  const primaryKeys = keys.filter(key => key.primary);
  const secondaryKeys = keys.filter(key => !key.primary);

  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <Card>
            <Link to="/account/teams" className="text-base text-gray-500">
              &laquo; Back to licenses
            </Link>
            <div className="flex items-center mt-2 mb-6">
              {!state.editing && (
                <>
                  <h1 className="flex-1 flex text-3xl">{license.name || license.id}</h1>
                  {role === Roles.ADMIN && (
                    <Button onClick={() => dispatch({ type: 'edit' })}>Edit</Button>
                  )}
                </>
              )}
              {state.editing && (
                <>
                  <div className="flex-1">
                    <Form.Input
                      id="edit"
                      value={state.name}
                      placeholder="Edit license name"
                      onChangeText={value => dispatch({ type: 'update', value })}
                    />
                  </div>
                  <Button
                    onClick={() => updateName()}
                    className="ml-4"
                    disabled={!state.name || state.name.length < 3 || state.saving}
                  >
                    Update
                  </Button>
                  <Button
                    ghost
                    onClick={() => dispatch({ type: 'cancel' })}
                    className="ml-4"
                    disabled={state.saving}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
            <p className="text-base">
              This license allows you to use Notifee on a single production Android & iOS
              application via primary keys.
            </p>
            <div className="flex items-center">
              <div className="flex-1 flex items-center">
                <div className="inline-flex items-center text-base mr-4">
                  <Key size={14} className="mr-2" />
                  {keys.length} Keys
                </div>
                <div className="text-gray-300">|</div>
                {hasTeam && (
                  <div className="inline-flex items-center text-base mx-4">
                    <UserGroup size={14} className="mr-2" />
                    <Link to={`/account/teams/${team.id}`} className="hover:underline">
                      {team.name}
                    </Link>
                  </div>
                )}
                {!hasTeam && (
                  <div className="inline-flex items-center text-base mx-4">
                    <AssignTeam tid={tid} lid={lid} />
                  </div>
                )}
                <div className="text-gray-300">|</div>
                <div
                  className={cx('mx-4 px-2 py-1 rounded shadow text-sm', {
                    'bg-blue-300': license.type === LicenseType.STANDARD,
                    'bg-green-300': license.type === LicenseType.EXTENDED,
                  })}
                >
                  {license.type === LicenseType.STANDARD ? 'Standard' : 'Extended'}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="lg:w-1/4" />
      </div>
      <br />
      <Card style={{ padding: 0, minHeight: 350 }}>
        <div className="px-6 py-6 border-b-2">
          <div className="flex py-4">
            <div className="flex flex-1 items-center">
              <h2 className="text-2xl flex-1">Primary keys</h2>
              {role === Roles.ADMIN &&
                license.primary_ios_key_count + license.primary_android_key_count !== 2 && (
                  <GenerateKey
                    tid={tid}
                    lid={lid}
                    type="primary"
                    android={license.primary_android_key_count === 1}
                    ios={license.primary_ios_key_count === 1}
                    request={request}
                    onClick={() => {
                      // Handled by component
                    }}
                  >
                    Generate &raquo;
                  </GenerateKey>
                )}
            </div>
          </div>
          <p className="text-base">
            Primary keys are used to validate a license on a production application. Your license
            includes two platform specific keys for use on one Android & iOS application.
          </p>
        </div>
        <KeyTable role={role} keys={primaryKeys} team={tid} license={lid} request={request} />
      </Card>
      <br />
      <Card style={{ padding: 0, minHeight: 350 }}>
        <div className="px-6 py-6 border-b-2">
          <div className="flex py-4">
            <div className="flex flex-1 items-center">
              <h2 className="text-2xl flex-1">Secondary keys</h2>
              <GenerateKey
                tid={tid}
                lid={lid}
                type="secondary"
                android={false}
                ios={false}
                request={request}
                onClick={() => {
                  // Handled by component
                }}
              >
                Generate &raquo;
              </GenerateKey>
            </div>
          </div>
          <p className="text-base">
            Secondary keys are useful for non-production applications, such as a testing or staging
            version of your application. These keys are subject to stricter checks, and may fail
            cause Notifee to stop working if they&apos;re used on a production app.
          </p>
        </div>
        <KeyTable role={role} keys={secondaryKeys} team={tid} license={lid} request={request} />
      </Card>
    </>
  );
}

export default License;

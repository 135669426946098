import React, { useState } from 'react';
import { Button, ButtonProps } from '@invertase/ui';

import Modal from '../../../components/Modal';
import Form from '../../../components/Form';
import { inviteTeamMember } from '../../../hooks/useApi';
import { Roles } from '../../../types/account';

interface Props extends ButtonProps {
  team: string;
  onInvite: () => void;
}

function InviteMember({ team, children, onInvite, ...props }: Props) {
  const invite = inviteTeamMember();

  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<string>('');
  const [role, setRole] = useState<Roles>(Roles.USER);
  const [error, setError] = useState<string>('');

  function close() {
    if (!loading) {
      onInvite();
      setVisible(false);
    }
  }

  function onAdd() {
    if (user) {
      setLoading(true);
      invite(team, user, role)
        .then(close)
        .catch(setError)
        .finally(() => {
          setLoading(false);
          setUser('');
        });
    }
  }

  return (
    <>
      <Modal title="Invite User" visible={visible} onClose={close}>
        <p className="text-base">
          Invite a GitHub user to join this team by entering their GitHub username below. Once
          invited, the user can sign-in to Notifee and accept the invitation.
        </p>
        <Form>
          <Form.Group>
            <Form.Label for="username">GitHub Username:</Form.Label>
            <div className="flex">
              <div className="flex-1" />
              <Form.Input id="username" value={user} onChangeText={setUser} />
            </div>
            <Form.Error visible={!!error} message={error} />
          </Form.Group>
          <Form.Group>
            <Form.Label for="role">Role:</Form.Label>
            <Form.Hint
              visible
              message="Admins can invite other team members & manage license keys."
            />
            <Form.Select
              id="role"
              value={`${role}`}
              onChangeOption={role => setRole(parseInt(role, 10))}
            >
              <Form.Option value={Roles.USER.toString()} label="User" />
              <Form.Option value={Roles.ADMIN.toString()} label="Admin" />
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <div className="flex justify-end">
              <Button ghost onClick={close} className="mx-1">
                Close
              </Button>
              <Button disabled={loading} onClick={onAdd} className="mx-1">
                {loading ? 'Adding...' : 'Add'}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal>
      <Button {...props} onClick={() => setVisible(true)}>
        {children}
      </Button>
    </>
  );
}

export default InviteMember;

import React from 'react';
import { icons } from '@invertase/ui';

import Card from '../../../components/Card';

import List from './List';

const { Key } = icons;

function Licenses() {
  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <Card>
            <div className="flex">
              <h1 className="flex flex-1 items-center text-3xl">
                <Key size={28} className="mr-2" />
                Licenses
              </h1>
            </div>
            <p className="font-semibold text-base">
              A license can be used on a single Android & iOS application. You can configure
              multiple keys on a single license.
            </p>
            <p className="text-base">
              A license is only required when releasing an application. If you have multiple release
              builds (e.g. staging) you can create a secondary key which can be used alongside your
              production license.
            </p>
          </Card>
        </div>
        <div className="lg:w-1/4" />
      </div>
      <br />
      <List />
    </>
  );
}

export default Licenses;

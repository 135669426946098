import { Config } from '../types/account';
import useFirebase from './useFirebase';
import { useEffect, useState } from 'react';

type UseConfig = [boolean, Config];

const initialState: Config = {
  account: false,
  payment: false,
};

export default function useConfig(): UseConfig {
  const firebase = useFirebase();
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<Config>(initialState);

  useEffect(() => {
    if (!firebase) {
      return;
    }

    return firebase
      .firestore()
      .collection('_config')
      .doc('website')
      .onSnapshot(documentSnapshot => {
        setConfig((documentSnapshot?.data() as Config) || initialState);
        setLoading(false);
      });
  }, [firebase]);

  return [loading, config];
}

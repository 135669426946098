import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { icons } from '@invertase/ui';

import { Roles, TeamMember, User } from '../../../types/account';
import Card from '../../../components/Card';
import Link from '../../../components/Link';
import PopConfirm from '../../../components/PopConfirm';
import { UserContext } from '../../../hooks/useUser';
import { removeTeamMember, updateTeamMember } from '../../../hooks/useApi';
import Form from '../../../components/Form';
import { roleToString } from '../../../utils/firestore';

const { GitHub, User: UserIcon, Trash } = icons;

interface Props {
  team: string;
  role: Roles; // user role
  request: () => Promise<void>;
  members: {
    member: TeamMember;
    user: User;
  }[];
}

function Members({ team, role, members, request }: Props) {
  const currentUser = useContext(UserContext);
  const [pending, setPending] = useState<{ [key: string]: boolean }>({});
  const remove = removeTeamMember();
  const update = updateTeamMember();

  if (!currentUser) {
    return null;
  }

  function updateRole(mid: string, role: Roles) {
    setPending($ => ({ ...$, [mid]: true }));
    update(team, mid, role)
      .then(request)
      .then(() => {
        setPending($ => ({ ...$, [mid]: false }));
      });
  }

  return (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <UserIcon size={16} className="mr-2" />
          <h3 className="text-2xl">Team Members</h3>
        </div>
      </div>
      <div className="overflow-y-auto h-48">
        <table>
          <tbody>
            {members.map(({ member, user }) => (
              <tr key={user.id}>
                <td className="pl-4" style={{ width: 55 }}>
                  <div className="flex items-center">
                    <img src={user.photo_url} alt="" className="w-8 h-8 rounded-full shadow" />
                  </div>
                </td>
                <td>
                  <div>{user.display_name}</div>
                  <div className="inline-flex items-center">
                    <GitHub size={16} className="mr-1" />
                    <Link
                      to={user.provider_html_url}
                      target="_blank"
                      className="text-base text-gray-600 hover:underline hover:text-gray-900"
                    >
                      {user.provider_login.toLowerCase()}
                    </Link>
                  </div>
                </td>
                <td>
                  {role === Roles.USER && (
                    <div
                      className={cx('inline-flex px-2 py-1 rounded shadow text-sm', {
                        'bg-gray-300': member.role === Roles.USER,
                        'bg-teal-300': member.role === Roles.ADMIN,
                      })}
                    >
                      {roleToString(member.role)}
                    </div>
                  )}
                  {role === Roles.ADMIN && !pending[member.id] && (
                    <Form.Select
                      id="role"
                      disabled={member.id === currentUser.id}
                      value={`${member.role}`}
                      onChangeOption={option => {
                        updateRole(member.id, parseInt(option, 10));
                      }}
                    >
                      <Form.Option value={`${Roles.ADMIN}`} label="Admin" />
                      <Form.Option value={`${Roles.USER}`} label="User" />
                    </Form.Select>
                  )}
                  {role === Roles.ADMIN && pending[member.id] && (
                    <div className="text-gray-500">Updating...</div>
                  )}
                </td>
                {role === Roles.ADMIN && (
                  <td style={{ width: 40 }}>
                    {member.id !== currentUser.id && (
                      <PopConfirm
                        title="Remove Member"
                        description={
                          <p>
                            Removing a member from a team will mean they no longer have access to
                            the licenses this team owns. To remove the member{' '}
                            <code>{user.provider_login.toLowerCase()}</code>, enter their username
                            into the box below.
                          </p>
                        }
                        match={user.provider_login.toLowerCase()}
                        onConfirm={async () => {
                          await remove(team, member.id);
                          await request();
                        }}
                      >
                        <Trash />
                      </PopConfirm>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default Members;

import getFirebase from './index';

export const signOutUser = async () => {
  const firebase = getFirebase();

  if (!firebase) {
    return [null];
  }

  const user = firebase.auth().currentUser;

  if (user) {
    await firebase.auth().signOut();
  }

  return Promise.resolve();
};

import React, { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { Button, icons } from '@invertase/ui';

import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import { getInvoice, getInvoices } from '../../../hooks/useApi';

const { CreditCard } = icons;

function Invoices() {
  const [fetching, setFetching] = useState<{ [key: string]: boolean }>({});
  const { loading, response: invoices, error } = getInvoices();
  const getInvoiceUrl = getInvoice();

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <CreditCard size={22} className="mr-4" />
          <h3 className="text-2xl">Invoices</h3>
        </div>
      </div>
      <div className="overflow-y-auto h-48">{children}</div>
    </Card>
  );

  function openInvoice(iid: string) {
    setFetching($ => ({ ...$, [iid]: true }));
    getInvoiceUrl(iid).then(({ url }) => {
      const tab = window.open(url, '_blank');
      tab?.focus();

      setFetching($ => ({ ...$, [iid]: false }));
    });
  }

  if (loading) {
    return wrapper(<Loader size="sm" />);
  }

  if (error || !invoices.length) {
    let e = 'You have no invoices';
    if (error) e = error;
    return wrapper(<div className="h-full flex items-center justify-center text-base">{e}</div>);
  }
  return wrapper(
    <div className="overflow-y-scroll h-48">
      <table>
        <tbody>
          {invoices.map(invoice => (
            <tr key={invoice.id}>
              <td className="pl-4">{dayjs(invoice.created_at).format('DD MMM YYYY HH:mm')}</td>
              <td>
                {invoice.metadata.p_currency} {invoice.metadata.p_price}
              </td>
              <td>
                <Button
                  size="sm"
                  ghost
                  onClick={() => openInvoice(invoice.id)}
                  disabled={fetching[invoice.id]}
                >
                  {fetching[invoice.id] && 'Retrieving...'}
                  {!fetching[invoice.id] && 'Download'}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>,
  );
}

export default Invoices;

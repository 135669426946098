import * as React from 'react';
import cx from 'classnames';

interface Props {
  visible: boolean;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
}

function Modal({ visible, title, children, onClose }: Props) {
  if (typeof window !== 'undefined') {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  return (
    <>
      <div
        tabIndex={-1}
        className={cx('fixed inset-0 z-40', {
          'invisible pointer-events-none': !visible,
        })}
        style={{ backgroundColor: 'rgba(82, 95, 127, 0.25)' }}
        onClick={() => onClose()}
        onKeyPress={() => onClose()}
      />
      <div
        className={cx(
          'fixed z-50 w-full max-w-lg bg-gray-200 dark:bg-gray-900 rounded-lg shadow-lg border-b',
          {
            'invisible pointer-events-none': !visible,
          },
        )}
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <div className="p-4 flex items-center font-thin text-lg">{title}</div>
        <div
          className="p-4 bg-white dark:bg-gray-800 border-t overflow-y-auto rounded-br-lg rounded-bl-lg"
          style={{ maxHeight: '75vh' }}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;

import React, { ReactNode, useState } from 'react';
import { Button } from '@invertase/ui';

import Modal from '../../../components/Modal';
import Form from '../../../components/Form';
import { assignTeam, getTeams } from '../../../hooks/useApi';
import Loader from '../../../components/Loader';
import Link from '../../../components/Link';

interface Props {
  tid: string;
  lid: string;
}

function AssignTeam({ tid, lid }: Props) {
  const { loading, response: teams, error } = getTeams();
  const assign = assignTeam();

  const [visible, setVisible] = useState<boolean>(false);
  const [assigning, setAssigning] = useState<boolean>(false);
  const [team, setTeam] = useState<string>('');

  function close() {
    setVisible(false);
  }

  function onAssign() {
    if (team) {
      setAssigning(true);
      assign(tid, lid, team).then(() => {
        window.location.replace(`/account/licenses/${lid}/${team}`);
      });
    }
  }

  const action = (
    <Button size="sm" onClick={() => setVisible(true)} disabled={assigning}>
      {assigning ? 'Assigning...' : 'Assign Team'}
    </Button>
  );

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <>
      <Modal title={`Assign a team`} visible={visible} onClose={close}>
        <p className="text-base">
          <span>
            Assign a team to this license to allow the users of the team to see & manage the
            license. Once assigned, you will no longer own the license.
          </span>
        </p>
        {children}
      </Modal>
      {action}
    </>
  );

  if (loading) {
    return wrapper(
      <div className="py-6">
        <Loader />
      </div>,
    );
  }

  if (error || !teams.length) {
    let e = (
      <span>
        No teams available to assign.{' '}
        <Link to="/account/teams" className="text-gray-500 hover:underline">
          Create team &raquo;
        </Link>
      </span>
    );

    if (error) e = <span>{error}</span>;

    return wrapper(
      <>
        <div className="py-6">{e}</div>
        <Button ghost onClick={close} className="mx-1">
          Close
        </Button>
      </>,
    );
  }

  return wrapper(
    <>
      <Form>
        <Form.Group>
          <Form.Select id="team" value={team} onChangeOption={setTeam}>
            <Form.Option label="Select a team" value="" />
            {teams.map(({ team }) => (
              <Form.Option key={team.id} value={team.id} label={team.name} />
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <div className="flex justify-end">
            <Button ghost onClick={close} className="mx-1">
              Close
            </Button>
            <Button disabled={!team} onClick={onAssign} className="mx-1">
              Assign
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>,
  );
}

export default AssignTeam;

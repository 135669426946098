import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import { icons } from '@invertase/ui';

import { getInvites } from '../../../hooks/useApi';
import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import Link from '../../../components/Link';

const { Envelope } = icons;

function Invites() {
  const { loading, response: invites, error } = getInvites();

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <Envelope size={22} className="mr-4" />
          <h3 className="text-2xl">Invites</h3>
        </div>
      </div>
      <div className="overflow-y-auto h-48">{children}</div>
    </Card>
  );

  if (loading) {
    return wrapper(<Loader size="sm" />);
  }

  if (error || !invites.length) {
    let e = 'You have no invites';
    if (error) e = error;
    return wrapper(<div className="h-full flex items-center justify-center text-base">{e}</div>);
  }

  return wrapper(
    <table>
      <tbody>
        {!loading &&
          invites.map(invite => (
            <tr key={invite.id}>
              <td className="pl-4" style={{ width: 60 }}>
                <img
                  src={invite.inviter_photo_url}
                  alt=""
                  className="w-8 h-8 rounded-full shadow"
                />
              </td>
              <td className="text-sm">
                <span>You have been invited to </span>
                <b>{invite.team_name}</b>
                <span> by </span>
                <Link
                  to={invite.inviter_provider_html_url}
                  target="_blank"
                  className="font-semibold hover:underline"
                >
                  {invite.inviter_provider_login}
                </Link>
                <span> on the </span>
                {dayjs(invite.created_at).format('DD/MM/YYYY')}.
              </td>
            </tr>
          ))}
      </tbody>
    </table>,
  );
}

export default Invites;

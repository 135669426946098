import * as React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  style?: React.CSSProperties;
}

function Card({ children, className, style }: Props) {
  return (
    <div
      className={cx('bg-white dark:bg-gray-900 rounded-lg shadow px-10 py-8', className)}
      style={style}
    >
      {children}
    </div>
  );
}

export default Card;

import React, { ReactNode } from 'react';
import { icons } from '@invertase/ui';

import { License } from '../../../types/account';
import Card from '../../../components/Card';
import Link from '../../../components/Link';

const { Key } = icons;

interface Props {
  licenses: License[];
}

function Licenses({ licenses }: Props) {
  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <Key size={16} className="mr-2" />
          <h3 className="text-2xl">Team Licenses</h3>
        </div>
      </div>
      <div className="overflow-y-auto h-48">{children}</div>
    </Card>
  );

  if (licenses.length === 0) {
    return wrapper(
      <div className="py-12 flex flex-col items-center justify-center">
        This team has no licenses
      </div>,
    );
  }

  return (
    <Card style={{ padding: 0 }}>
      <div className="flex px-4 py-4 h-16 border-b-2">
        <div className="flex items-center flex-1">
          <Key size={16} className="mr-2" />
          <h3 className="text-2xl">Team Licenses</h3>
        </div>
      </div>
      <div className="overflow-y-auto h-48">
        <table>
          <tbody>
            {licenses.map(license => (
              <tr key={license.id}>
                <td className="pl-6">{license.name || license.id}</td>
                <td>
                  <div className="flex items-center">
                    <Key size={16} className="mr-2" />
                    {license.key_count} keys
                  </div>
                </td>
                <td>
                  <Link
                    to={`/account/licenses/${license.id}/${license.tid}`}
                    className="text-gray-500 hover:underline"
                  >
                    View &raquo;
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default Licenses;

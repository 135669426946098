import * as React from 'react';
import { ReactNode } from 'react';
import cx from 'classnames';
import { icons } from '@invertase/ui';

import Loader from '../../../components/Loader';
import Card from '../../../components/Card';

import { getTeams } from '../../../hooks/useApi';
import Link from '../../../components/Link';
import { Roles } from '../../../types/account';

const { UserGroup, Key, Envelope } = icons;

function TeamsList() {
  const { loading, response: teams, error } = getTeams();

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <Card style={{ padding: 0, minHeight: 300 }}>
      <div className="px-6 pt-6 border-b-2">
        <h2 className="text-2xl mb-6">Teams</h2>
      </div>
      {children}
    </Card>
  );

  if (loading) {
    return wrapper(
      <div className="py-6">
        <Loader />
      </div>,
    );
  }

  if (error || !teams.length) {
    let e = 'You are not a member of any teams';
    if (error) e = error;
    return wrapper(<div className="py-12 flex items-center justify-center text-base">{e}</div>);
  }

  return wrapper(
    <div style={{ minHeight: 200 }}>
      <table>
        <tbody>
          {teams.map(({ role, team }) => (
            <tr key={team.id}>
              <td className="pl-6">{team.name || team.id}</td>
              <td>
                <span
                  className={cx('px-2 py-1 rounded shadow text-sm', {
                    'bg-gray-300': role === Roles.USER,
                    'bg-teal-300': role === Roles.ADMIN,
                  })}
                >
                  {role === Roles.ADMIN ? 'admin' : 'user'}
                </span>
              </td>
              <td>
                <div className="inline-flex items-center text-sm">
                  <UserGroup size={16} className="mr-2" />
                  {team.member_count} members
                </div>
              </td>
              <td>
                <div className="inline-flex items-center text-sm">
                  <Key size={16} className="mr-2" />
                  {team.license_count} licenses
                </div>
              </td>
              <td>
                <div className="inline-flex items-center text-sm">
                  <Envelope size={16} className="mr-2" />
                  {team.pending_invite_count} pending invites
                </div>
              </td>
              <td>
                <Link className="text-gray-500 hover:underline" to={`/account/teams/${team.id}`}>
                  View &raquo;
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>,
  );
}

export default TeamsList;
